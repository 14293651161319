import * as React from 'react'
import GoogleMapReact from 'google-map-react'
import classNames from 'classnames'
import { PhoneIcon, MailIcon } from '@heroicons/react/outline'
import Facebook from '../images/svg/facebook.svg'
import Instagram from '../images/svg/instagram.svg'
import SEO from '../components/SEO'
import Layout from '../components/Layout/Layout'
import PageContent from '../components/PageContent/PageContent'
import * as styles from '../styles/kontakt.module.scss'
import Marker from '../components/Marker/Marker'

function KontaktPage() {
  return (
    <Layout>
      <SEO title="Regulamin" description="Regulamin salonu" />
      <div
        className={classNames(
          'h-screen relative max-w-[100vw] overflow-x-hidden',
          styles.container,
          styles.container__background
        )}
      >
        <PageContent title="KONTAKT">
          <div className="w-[80%] m-auto text-center">
            <div className="mb-2">
              <PhoneIcon className="w-5 h-5 inline" />
              <span className="ml-5">732 062 396</span>
            </div>
            <div className="mb-2">
              <MailIcon className="w-5 h-5 inline" />
              <span className="ml-5">kontakt@hekate.dog</span>
            </div>
            <div className="mb-5">
              <a
                href="https://www.facebook.com/hekate.salon.psiej.urody"
                rel="noreferrer"
                target="_blank"
              >
                <Facebook className="inline mr-5" />
              </a>
              <a
                href="https://l.facebook.com/l.php?u=http%3A%2F%2Finstagram.com%2Fhekate_salon_psiej_urody%3Ffbclid%3DIwAR14YcpP__NG2PHR_BTUzfqJsiFJRBdcxLNvzH8Zy_1HiDuA1KCXCZl_dkU&h=AT3fKyPOWzPX1ly6_QhQ8yD5uI31KR3UpG7VD22G0q3KW2OPT6WK7EPYYsmsnJiUpGbrmD1-WP_JJsiiC9czESDOOBoJ0DT0l9d7g9riwj19w1_LMxsZPIxSM-dozjkShjO39vKjHE4"
                rel="noreferrer"
                target="_blank"
              >
                <Instagram className="inline" />
              </a>
            </div>
            <div className="mb-5">ul. 10 lutego 1/u5, Wejherowo</div>
            <div className={styles.mapBorder}>
              <div style={{ height: '50vh', width: '100%' }}>
                <GoogleMapReact
                  bootstrapURLKeys={{
                    key: 'AIzaSyAuGUoQaV4uS-l3owjxYFkRtsr2YtPQFl0'
                  }}
                  defaultCenter={{
                    lat: 54.60471610907092,
                    lng: 18.23448232801838
                  }}
                  defaultZoom={16}
                  yesIWantToUseGoogleMapApiInternals
                >
                  <Marker
                    lat={54.60471610907092}
                    lng={18.23448232801838}
                    name="Hekate - Salon Psiej Urody"
                    color="#E0AD39"
                  />
                </GoogleMapReact>
              </div>
            </div>
          </div>
        </PageContent>
      </div>
    </Layout>
  )
}

export default KontaktPage

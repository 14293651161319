import * as React from 'react'
import './Marker.scss'

type MarkerProps = {
  lat: number
  lng: number
  name: string
  color: string
}

function Marker(props: Partial<MarkerProps>) {
  const { name } = props
  return (
    <div>
      <div className="pin bounce" style={{ cursor: 'pointer' }} title={name} />
      <div className="pulse" />
    </div>
  )
}

export default Marker
